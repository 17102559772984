/**
 * A set of default values for the attributes of the tree graph
 */



/**
 * Base description for node shapes
 * @type {{width: number, radius: number, height: number}}
 */

const pillShapeNode = {
    width: 100,
    height: 30,
    radius: 5,
};
const circleShapeNode = {
    width: 20,
    height: 20,
    radius: 10,
};

export const fontHeight = 8; // Height of the font used in nodes (px)
export const nodeSpaceVertical = 40; // Vertical spacing between nodes, should greater than highest node shape
export const minSpaceHorizontal = 150; // The minimum horizontal space between nodes, should be greater than the width of the node shape

/**
 * Parameters for presenting nodes for each entity Type, style
 *
 * Colors: https://htmlcolorcodes.com/color-chart/
 */

export const nodeParams = {
    'Default': {
        style: {
            color: '#DDD',
            shape: pillShapeNode,
        },
        label: 'Default',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Asset': {
        style: {
            color: '#EBDEF0',
            shape: pillShapeNode,
        },
        label: 'Asset',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'AssetSC': {
        style: {
            color: '#C39BD3',
            shape: pillShapeNode,
        },
        label: 'Asset SC',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Character': {
        style: {
            color: '#EAF2F8',
            shape: pillShapeNode,
        },
        label: 'Character',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'CreativeWork': {
        style: {
            color: '#FEF5E7',
            shape: pillShapeNode,
        },
        label: 'Creative Work',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Context': {
        style: {
            color: '#F5B041',
            shape: circleShapeNode,
        },
        label: null,
        displayProperty: ['contextType', 'name'],
        query: {
            allow: true
        }
    },
    'Depiction': {
        style: {
            color: '#FAD7A0',
            shape: pillShapeNode,
        },
        label: 'Depiction',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Effect': {
        style: {
            color: '#E8F8F5',
            shape: pillShapeNode,
        },
        label: 'Effect',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Composition': {
        style: {
            color: '#D6DBDF',
            shape: pillShapeNode,
        },
        label: 'Location',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Location': {
        style: {
            color: '#AEB6BF',
            shape: pillShapeNode,
        },
        label: 'Location',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeAudio': {
        style: {
            color: '#D4E6F1',
            shape: pillShapeNode,
        },
        label: 'Nar. Audio',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeLocation': {
        style: {
            color: '#A9CCE3',
            shape: pillShapeNode,
        },
        label: 'Nar. Location',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeScene': {
        style: {
            color: '#A9CCE3',
            shape: pillShapeNode,
        },
        label: 'Nar. Scene',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeObject': {
        style: {
            color: '#7FB3D5',
            shape: pillShapeNode,
        },
        label: 'Nar. Object',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeStyling': {
        style: {
            color: '#5499C7',
            shape: pillShapeNode,
        },
        label: 'Nar. Styling',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'NarrativeWardrobe': {
        style: {
            color: '#2980B9',
            shape: pillShapeNode,
        },
        label: 'Nar. Wardrobe',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Participant': {
        style: {
            color: '#F9EBEA',
            shape: pillShapeNode,
        },
        label: 'Participant',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Person': {
        style: {
            color: '#E6B0AA',
            shape: pillShapeNode,
        },
        label: 'Person',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Organization': {
        style: {
            color: '#E6B0AA',
            shape: pillShapeNode,
        },
        label: 'Organization',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Department': {
        style: {
            color: '#E6B0AA',
            shape: pillShapeNode,
        },
        label: 'Department',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Service': {
        style: {
            color: '#E6B0AA',
            shape: pillShapeNode,
        },
        label: 'Service',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'Role': {
        style: {
            color: '#CD6155',
            shape: pillShapeNode,
        },
        label: 'Role',
        displayProperty: ['name'],
        query: {
            allow: false
        }
    },
    'ProductionScene': {
        style: {
            color: '#7DCEA0',
            shape: pillShapeNode,
        },
        label: 'Prod. Scene',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'ProductionLocation': {
        style: {
            color: '#A9DFBF',
            shape: pillShapeNode,
        },
        label: 'Prod. Location',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'Slate': {
        style: {
            color: '#52BE80',
            shape: pillShapeNode,
            icon: 'M10.8,19a2.69,2.69,0,0,0,.18-.84L42.2,9.79,40.9,5A4,4,0,0,0,36,2.14L9,9.38a3.91,3.91,0,0,0-1.62.9l0,0a4,4,0,0,0-.89,1.2h0a4,4,0,0,0-.28,2.76l.31,1.17A3,3,0,0,0,7,20.82V44a4,4,0,0,0,4,4H39a4,4,0,0,0,4-4V19Zm26.79,2-5,5H27.41l5-5Zm-8,0-5,5H19.41l5-5Zm-8,0-5,5H11.41l5-5ZM9,25.59V21h4.59ZM36.52,4.07A2,2,0,0,1,39,5.48l.78,2.9-.57.15L33.06,5ZM30.33,5.73l6.12,3.53-5,1.34L25.33,7.07ZM22.6,7.8l6.12,3.53-5,1.34L17.6,9.14ZM14.87,9.87,21,13.4l-5,1.34L9.88,11.21ZM8.14,12.51l5.13,3-2.85.76a3,3,0,0,0-2-1.2l-.35-1.28A2,2,0,0,1,8.14,12.51ZM7.06,17.73a.77.77,0,0,1,.12-.26l0-.06a1.29,1.29,0,0,1,.16-.17l.13-.09.15-.08A1,1,0,0,1,8,17a1,1,0,0,1,1,1,.93.93,0,0,1-.07.33.76.76,0,0,1,0,.11.83.83,0,0,0-.08.12,1.18,1.18,0,0,1-.25.25A1,1,0,0,1,8,19a1,1,0,0,1-1-1A.82.82,0,0,1,7.06,17.73ZM41,44a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2V28H41Zm0-18H35.41l5-5H41Z'
        },
        label: 'Slate',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
    'SpecialAction': {
        style: {
            color: '#A3E4D7',
            shape: pillShapeNode,
        },
        label: 'Special Action',
        displayProperty: ['name'],
        query: {
            allow: true
        }
    },
};
