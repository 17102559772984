import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
// import { select } from 'd3-selection';
// import { zoom, zoomIdentity } from 'd3-zoom';
import { Box, useStyleConfig } from '@chakra-ui/react';
// import useTraceUpdate from '../../hooks/useTraceUpdate';

function D3Wrapper(props) {
    const {
        treeId, // The DOM #id for the container
        d3ContainerSize,
        // setZoomPosition = () => {}, // After any changes to Zoom, the new position is stored for use
        children,
    } = props;

    // console.log('Fired the D3ZoomWrapper');
    // useTraceUpdate(props);

    // const zoomRef = useRef(null);
    const omcDisplayFrame = useStyleConfig('DisplayFrame');

    // const handleZoom = (({ transform }) => {
    //     const zElement = select(zoomRef.current)
    //         .select(`#${treeId}-container`);
    //     zElement.attr('transform', transform); // Apply Zoom transform to the g containing the tree nodes
    // });
    //
    // const treeZoom = zoom()
    //     .on('end', ({ transform }) => setZoomPosition(transform)) // Save the current zoom position
    //     .on('zoom', handleZoom);
    //
    // const initialZoom = (ch) => zoomIdentity.translate(120, ch / 2).scale(1);
    //
    // useEffect(() => {
    //     const svg = select(zoomRef.current); // Reset the node starting position and zoom scale
    //     svg.call(treeZoom.transform, initialZoom(d3ContainerSize.height));
    //     svg.call(treeZoom); // The zoom is set on the containing <div>
    // }, [d3ContainerSize]);

    return (
        <Box
            __css={omcDisplayFrame}
            mr={1}
            h={d3ContainerSize.height}
            w="100%"
        >
            <svg
                id={treeId}
                width="100%"
                height={d3ContainerSize.height}
            >
                <g id={`${treeId}-container`}>
                    <g className="treeLink" />
                    <g className="treeNode" />
                </g>
            </svg>
            {children}
        </Box>
    );
}

export default React.memo(D3Wrapper);
