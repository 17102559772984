/* eslint-disable */
import React, { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Link, Box, Flex, Text, Button, Stack,
} from '@chakra-ui/react';
import { useOktaAuth } from '@okta/okta-react';
import MessagingAuth from '../../Auth/MessagingAuth';

import Logo from './Logo';

function CloseIcon() {
    const svgCode = 'M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502'
        + '15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023'
        + '7.58599Z';

    // removed from the svg element below: xlmns="http://www.w3.org/2000/svg"
    return (
        <svg width="24" viewBox="0 0 18 18">
            <title>Close</title>
            <path
                fill="white"
                d={svgCode}
            />
        </svg>
    );
}

function MenuIcon() {
    return (
        <svg
            width="24px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
        >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
        </svg>
    );
}

function MenuToggle({
    isOpen = false,
    toggle = () => {},
}) {
    return (
        <Box
            display={{
                base: 'block',
                md: 'none',
            }}
            onClick={toggle}
        >
            {isOpen ? <CloseIcon/> : <MenuIcon/>}
        </Box>
    );
}

MenuToggle.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

function MenuItem({
    children,
    to = '/',
}) {
    return (
        <Link as={ReactLink} to={to}>
            <Text display="block">
                {children}
            </Text>
        </Link>
    );
}

MenuItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    to: PropTypes.string,
};

function MenuLinks({
    isOpen = false,
    authAction ={
        text: '',
        action: () => {
        },
    },
}) {
    return (
        <Box
            display={{
                base: isOpen ? 'block' : 'none',
                md: 'block',
            }}
            flexBasis={{
                base: '100%',
                md: 'auto',
            }}
        >
            <Stack
                spacing={8}
                align="center"
                justify={['center', 'space-between', 'flex-end', 'flex-end']}
                direction={['column', 'row', 'row', 'row']}
                pt={[4, 4, 0, 0]}
            >
                <MenuItem to="/admin">Admin</MenuItem>
                <MenuItem to="/approval">Approval</MenuItem>
                <MenuItem to="/storyboard">Storyboard</MenuItem>
                <MenuItem to="/conceptart">Concept Art</MenuItem>
                <MenuItem to="/relationships">Relationships</MenuItem>
                <MenuItem to="/omc">OMC</MenuItem>
                <MenuItem to="/skosEditor">SKOS Vocab</MenuItem>
                <MenuItem to="/omcjsonEditor">OMC-JSON</MenuItem>
                <MenuItem to="/profile">Profile</MenuItem>
                <Button
                    size="sm"
                    rounded="md"
                    color={['primary.500', 'primary.500', 'white', 'white']}
                    bg={['white', 'white', 'primary.500', 'primary.500']}
                    _hover={{
                        bg: ['primary.100', 'primary.100', 'primary.600', 'primary.600'],
                    }}
                    onClick={authAction.action}
                >
                    {authAction.text}
                </Button>
            </Stack>
        </Box>
    );
}

MenuLinks.propTypes = {
    isOpen: PropTypes.bool,
    authAction: PropTypes.shape({
        text: PropTypes.string,
        action: PropTypes.func,
    }),
};

function UserName({ name }) {
    return (
        <Text>
            {name}
        </Text>
    );
}

UserName.propTypes = {
    name: PropTypes.string,
};

function NavBarContainer({ children }) {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            mb={8}
            p={8}
            bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
            color={['white', 'white', 'primary.700', 'primary.700']}
        >
            {children}
        </Flex>
    );
}

NavBarContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

/*
Create a navigation bar
 */
export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const navigate = useNavigate();
    const {
        oktaAuth,
        authState,
    } = useOktaAuth();
    if (!authState) return null;
    const authName = authState.isAuthenticated ? authState.idToken.claims.email : ''; // Users name
    const authAction = authState.isAuthenticated
        ? {
            text: 'Sign Out',
            action: () => {
                MessagingAuth.signOut();
                oktaAuth.signOut();
            },
        }
        : {
            text: 'Sign In',
            action: () => navigate('/profile'),
        };

    return (
        <NavBarContainer>
            <Logo
                w="100px"
                color={['white', 'white', 'primary.500', 'primary.500']}
            />
            <MenuToggle toggle={toggle} isOpen={isOpen} />
            <Stack direction="column" justify="right">
                <UserName name={authName} />
                <MenuLinks
                    authAction={authAction}
                />
            </Stack>
        </NavBarContainer>
    );
}
