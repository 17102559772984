import { useEffect } from 'react';
import { select } from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { drag as d3Drag } from 'd3-drag';
import { rgb as d3Rgb } from 'd3-color';
import { sankey as d3Sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { line } from 'd3-shape';

const nodeWidth = 36;

const lineHeight = 15;
const baseNode = {
    radius: 15,
    width: 300,
    header: {
        label: 'Header',
        color: '#FAD7A0',
    },
    data: {
        identifier: '1234',
        // name: 'Name',
        // extra: 'Extra',
    },
};

function createHeader(params) {
    const { radius, width, header, data } = params;
    const dataLines = Object.keys(data);
    const height = (dataLines.length) * lineHeight;
    const path = `M0 ${radius + lineHeight}v -${lineHeight} a ${radius} ${radius} 0 0 1 ${radius} -${radius}h ${width}`
        + `a ${radius} ${radius} 0 0 1 ${radius} ${radius}v ${lineHeight}`
        + `a ${radius} ${radius} 0 0 1 -${radius} ${radius}h -${width} a ${radius} ${radius} 0 0 1 -${radius} -${radius}`;
    return path;
}

function createInfo(params) {
    const { radius, width, header, data } = params;
    const dataLines = Object.keys(data);
    if (!dataLines.length) return ''; // No data to display
    const height = (dataLines.length) * lineHeight;
    const path = `M 0 ${radius + lineHeight}h ${width + radius * 2} v ${height}`
        + `a ${radius} ${radius} 0 0 1 -${radius} ${radius}h -${width} a ${radius} ${radius} 0 0 1 -${radius} -${radius}z`;
    return path;
}


function createNode(params) {
    const { radius, width, header, data } = params;
    const dataLines = Object.keys(data);
    const height = (dataLines.length) * lineHeight;
    let path = `M0 ${radius + lineHeight}v -${lineHeight} a ${radius} ${radius} 0 0 1 ${radius} -${radius}h ${width}`
        + `a ${radius} ${radius} 0 0 1 ${radius} ${radius}v ${lineHeight}`;
    path += height
        ? `m 0 ${height} v -${height}  h -${width + radius * 2} 0 v ${height}`
        : `m -${width + radius * 2} 0`;
    path += `a ${radius} ${radius} 0 0 0 ${radius} ${radius}h ${width} a ${radius} ${radius} 0 0 0 ${radius} -${radius}`;
    return path;
}

function OmcRelationshipDiagram(
    {
        chartData,
        containerDimensions,
        treeId,
    },
) {
    if (!chartData) return;
    // console.log('Render OmcTreeDiagram');
    const { width, height } = containerDimensions;

    const color = scaleOrdinal(['#4269d0', '#efb118', '#ff725c', '#6cc5b0', '#3ca951', '#ff8ab7', '#a463f2', '#97bbf5', '#9c6b4e', '#9498a0']);

    const sankey = d3Sankey()
        .nodeId((d) => d.node)
        .nodeWidth(36)
        .nodePadding(20)
        .extent([[1, 5], [width - nodeWidth, height - 5]]);

    const { nodes, links } = sankey({
        nodes: chartData.nodes,
        links: chartData.links,
    });

    // the function for moving the nodes
    // function dragmove(d) {
    //     select(this)
    //         .attr(
    //             'transform',
    //             `translate(${
    //                 d.x},${
    //                 d.y = Math.max(0, Math.min(containerHeight - d.dy, d3.event.y))})`,
    //         );
    //     sankey.relayout();
    //     link.attr('d', sankey.link());
    // }

    useEffect(() => {
        function update(event, source) {
            const duration = event?.altKey ? 2500 : 250; // Hold the alt key to slow down the transition
            if (!chartData) return;

            // Select the main container using the tree's id
            const svg = select(`#${treeId}-container`); // The g that zoom is on.

            const link = svg.append('g')
                .selectAll('.link')
                .data(links)
                .enter()
                .append('path')
                .attr('class', 'link')
                .attr('fill', 'none')
                .attr('stroke', '#000')
                .attr('stroke-opacity', 0.2)
                .attr('d', sankeyLinkHorizontal())
                // .style('stroke-width', (d) => d.width)
                .style('stroke-width', 3);
            // .sort((a, b) => b.y0 - a.y0);

            // add in the nodes
            const node = svg.append('g')
                .selectAll('.node')
                .data(nodes)
                .enter()
                .append('g')
                .attr('class', 'node')
                // .attr('transform', (d) => `translate(${d.x0},${d.y0})`)
                .call(d3Drag()
                    .subject((d) => d)
                    .on('start', function() { this.parentNode.appendChild(this); })
                    .on('drag', () => {}));

            // add the rectangles for the nodes
            node
                .append('rect')
                .attr('x', (d) => d.x0)
                .attr('y', (d) => d.y0)
                .attr('height', (d) => d.y1 - d.y0)
                .attr('width', (d) => d.x1 - d.x0)
                .style('fill', (d) => d.color = color(d.name.replace(/ .*/, '')))
                .style('stroke', (d) => d3Rgb(d.color).darker(2))
                // Add hover text
                .append('title')
                .text((d) => `${d.name}\nThere is ${d.value} stuff in this node`);

            // add in the title for the nodes
            node
                .append('text')
                .attr('x', (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
                .attr('y', (d) => (d.y1 + d.y0) / 2)
                .attr('dy', '.35em')
                .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'end'))
                .text((d) => d.name);

            node
                .append('path')
                .attr('d', (d) => createHeader(baseNode))
                .style('fill', baseNode.header.color)
                .style('stroke', 'black')
                .style('stroke-width', 1);
            node
                .append('text')
                .attr('x', (d) => 10)
                .attr('y', (d) => lineHeight / 2)
                .attr('dy', '.35em')
                .attr('text-anchor', 'start')
                .text((d) => baseNode.header.label);
            node
                .append('path')
                .attr('d', (d) => createInfo(baseNode))
                .style('fill', '#eee')
                .style('stroke', 'black')
                .style('stroke-width', 1);
        }

        update(null);
    }, [chartData]);

    return null;
}

export default OmcRelationshipDiagram;
