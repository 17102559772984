/**
 * Main Page Component for displaying a tree based graph of OMC data
 */

import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import OmcRelationshipContainer from '../Components/Diagrams/OmcRelationships/OmcRelationshipContainer';
import LoadingSpinner from '../Components/Layout/Utilities/LoadingSpinner';

function OmcRelationships() {
    const { authState } = useOktaAuth();

    // If the user is not yet authorized or the vocabulary is not loaded return the spinner
    return (!authState.isAuthenticated)
        ? (<LoadingSpinner />)
        : (
            <OmcRelationshipContainer
                accessToken={authState.accessToken.accessToken}
            />
        );
}

export default OmcRelationships;
