/**
 * SVG Container for the D3 graph
 */

import React, { useState, useEffect, createRef } from 'react';

import {
    Box,
    Flex,
} from '@chakra-ui/react';

import D3Wrapper from '../D3Wrapper';
import OmcRelationshipDiagram from './OmcRelationshipDiagram';
import useWindowInnerSize from '../../../hooks/useWindowInnerSize.mjs';

const treeId = 'omcRelationship'; // ID for the tree
const graphTableRatio = 1; // The ratio of size for the top display to the bottom

const omcData = {
    nodes: [
        { node: 'C0', name: 'featuresIn' },
        { node: 'L0', name: 'NarrativeScene' },
        { node: 'L1', name: 'Character' },
        { node: 'L2', name: 'NarrativeObject' },
        { node: 'L3', name: 'NarrativeWardrobe' },
        { node: 'R0', name: 'NarrativeScene' },
        { node: 'R1', name: 'Character' },
        { node: 'R2', name: 'NarrativeObject' },
        { node: 'R3', name: 'NarrativeWardrobe' },
    ],
    links: [
        { source: 'L0', target: 'R1', value: 1 },
        { source: 'L0', target: 'R2', value: 1 },
        { source: 'L0', target: 'R3', value: 1 },
        { source: 'L1', target: 'C0', value: 1 },
        { source: 'L2', target: 'C0', value: 1 },
        { source: 'L3', target: 'C0', value: 1 },
        { source: 'C0', target: 'R0', value: 1 },
    ],
};

function OmcRelationshipContainer({
    // omcData,
}) {
    const containerRef = createRef(null); // Reference for container that holds the chart and table
    const { innerWidth, innerHeight } = useWindowInnerSize(); // Dimensions of container
    const [d3ContainerSize, setD3ContainerSize] = useState({ width: 0, height: 0 }); // D3 container dimensions
    const [tableContainerSize, setTableContainerSize] = useState({ width: 0, height: 0 }); // Table container dimensions

    const [chartData, setChartData] = useState(null);

    // Set the sizes for the chart and table containers
    useEffect(() => {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const cHeight = (innerHeight - containerTop); // Padding and margin adjustments
        setD3ContainerSize({ width: innerWidth, height: cHeight * graphTableRatio });
        setTableContainerSize({ width: innerWidth, height: cHeight * (1 - graphTableRatio) });
    }, [innerWidth, innerHeight]);

    useEffect(() => {
        if (!omcData || !d3ContainerSize) return;
        console.log(omcData);
        setChartData(omcData);
    }, [omcData]);

    return (
        <Box>
            <Flex direction="column" ref={containerRef} basis={d3ContainerSize.height}>
                <Flex direction="row" maxH={d3ContainerSize.height}>
                    <D3Wrapper
                        treeId={treeId}
                        d3ContainerSize={d3ContainerSize}
                    >
                        <OmcRelationshipDiagram
                            containerDimensions={d3ContainerSize}
                            chartData={chartData}
                            treeId={treeId}
                        />
                    </D3Wrapper>
                </Flex>
            </Flex>
        </Box>
    );
}

export default OmcRelationshipContainer;
